'use client'

import './AuthModal.scss'

import cn from 'classnames'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useEffect, useRef } from 'react'
import { useKey } from 'react-use'
import { clearBodyLocks, lock } from 'tua-body-scroll-lock'

import { isStorybook } from '@/src/app/config/env'
import ImageLogo from '@/src/shared/images/logo-white.svg'
import { Container } from '@/src/shared/ui/Container'
import PlusIcon from '@/src/shared/ui/Icon/assets/plus-icon.svg'

const Authorization = dynamic(
  () => import('@/src/features/auth/ui').then((mod) => mod.Authorization),
  {
    ssr: false,
  },
)

export type IAuthModalProps = {
  className?: string
  isOpen: boolean
  onChange: (isOpen: boolean) => void
}

const AuthModal = ({ isOpen, onChange, className }: IAuthModalProps) => {
  const scrollableRef = useRef<HTMLElement>(null)

  useKey('Escape', () => handleClose())

  const handleClose = () => {
    onChange(false)
  }

  useEffect(() => {
    if (isOpen) {
      lock(scrollableRef.current)
    } else {
      clearBodyLocks()
    }

    return () => clearBodyLocks()
  }, [isOpen])

  return (
    <section
      ref={scrollableRef}
      className={`${cn('auth-modal', {
        [`${className}`]: !!className,
        'auth-modal--is-open': isOpen,
      })}`}
    >
      <div className="auth-modal__top">
        <Container size="infinity">
          <div className="auth-modal__top-inner">
            <div className="auth-modal__logo">
              <Image src={ImageLogo} width={124} height={32} alt="logo-white" />
            </div>

            <div className="auth-modal__button-close">
              <button onClick={handleClose}>
                {!isStorybook && (
                  // TODO Bug: Broken storybook
                  <PlusIcon viewBox="0 0 39 39" width={28} height={28} name="plus-icon" />
                )}
              </button>
            </div>
          </div>
        </Container>
      </div>

      <div className="auth-modal__body">
        <Container size="infinity">
          <div className="auth-modal__body-inner">
            {isOpen && <Authorization onSuccessLogin={handleClose} />}
          </div>
        </Container>
      </div>
    </section>
  )
}

export default AuthModal
