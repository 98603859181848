/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import './Viewer.scss'

import classNames from 'classnames'
import { usePathname } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { useKey } from 'react-use'

import useClickAway from '@/src/shared/hooks/useClickAway'
import { Avatar } from '@/src/shared/ui/Avatar'
import { Button } from '@/src/shared/ui/Button'
import { Title } from '@/src/shared/ui/Title'

import { useAuthUser } from '../../hooks/useAuthUser'

export type IAuthProps = {
  slotToLogin: React.ReactNode
  slotLogoutButton: React.ReactNode
}

const Viewer = ({ slotToLogin, slotLogoutButton }: IAuthProps) => {
  const [isOpen, setDropState] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const pathname = usePathname()

  const { data, isAuth } = useAuthUser()

  useClickAway(() => {
    setDropState(false)
  }, ref)

  useKey('Escape', () => setDropState(false))

  useEffect(() => {
    if (isAuth && isOpen) {
      setDropState(false)
    }
  }, [pathname])

  return (
    <section className="viewer">
      {isAuth ? (
        <div
          ref={ref}
          className={classNames('viewer__dropdown', {
            'viewer__dropdown--is-open': isOpen,
          })}
        >
          <Button
            className="viewer__dropdown-toggler"
            variant="fourth"
            squared
            size="s"
            iconName="user-icon"
            onClick={() => setDropState(() => !isOpen)}
          />

          <div className="viewer__dropdown-body">
            <div className="viewer__dropdown-body-inner">
              <div className="viewer__avatar">
                <Avatar title="user-avatar" src={data?.avatar}></Avatar>
              </div>

              <div className="viewer__email">
                <Title size="3xs" ellipsis tag="h5">
                  {data?.email}
                </Title>
              </div>
              <div className="viewer__logout">{slotLogoutButton}</div>
            </div>
          </div>
        </div>
      ) : (
        <>{slotToLogin}</>
      )}
    </section>
  )
}

export default Viewer
