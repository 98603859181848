'use client'

import { useLocale } from 'next-intl'
import { useEffect } from 'react'
import useSWR, { preload } from 'swr'

import { SESSION_TOKEN_PLAYTEST_KEY_NAME } from '@/src/app/config/constants'
import { ILang } from '@/src/app/model/lang'
import { apiClientPlaytest } from '@/src/shared/api'
import { setSessionToken } from '@/src/shared/api/storage'
import { isClient } from '@/src/shared/lib/isServerOrClient'

import { getSessionPlaytest } from '../api'
import { SESSION_REVALIDATE_PLAYTEST_KEY } from '../config'

if (isClient) {
  preload([SESSION_REVALIDATE_PLAYTEST_KEY], () => getSessionPlaytest())
}

export const useSessionPlaytest = () => {
  const locale = useLocale()
  apiClientPlaytest.setLocale(locale as ILang)

  const { data } = useSWR([SESSION_REVALIDATE_PLAYTEST_KEY], () => getSessionPlaytest())

  const token = data?.token

  useEffect(() => {
    if (!token) return

    setSessionToken({ token, sessionTokenKeyName: SESSION_TOKEN_PLAYTEST_KEY_NAME })
  }, [token])

  return {
    status: data?.status,
    token,
  }
}
