import { IAuthUser } from '../../model'
import { IAuthUserDto } from '../dto'

export const mapAuthUser = (dto: IAuthUserDto): IAuthUser => {
  if (!dto?.data?.customer) return null

  return {
    id: dto.data?.customer?.id,
    email: dto.data?.customer?.email,
    avatar: dto.data?.customer?.avatar_link,
  }
}
