'use client'

import './LogOut.scss'

import { useTranslations } from 'next-intl'
import { useFormState, useFormStatus } from 'react-dom'

import { Button } from '@/src/shared/ui/Button'

import { logout } from '../../api'

const LogOut = () => {
  const [, actionLogout] = useFormState(logout, {
    data: null,
    message: null,
    errors: null,
    isError: false,
    isSuccess: false,
  })

  return (
    <form action={() => actionLogout()}>
      <SubmitLogout />
    </form>
  )
}

const SubmitLogout = () => {
  const t = useTranslations('Common')
  const { pending } = useFormStatus()

  return (
    <Button type="submit" loading={pending} size="2xs">
      {t('logout')}
    </Button>
  )
}

export default LogOut
