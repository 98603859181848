/* eslint-disable @typescript-eslint/no-unused-vars */
'use client'

import { createContext, Suspense, useContext } from 'react'
import { useToggle } from 'react-use'

import AuthModal from '../ui/AuthModal'

const AuthModalContext = createContext({
  authModalIsOpen: false,
  onAuthModalChange: (isOpen?: boolean): void => {},
})

export const AuthModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [on, toggle] = useToggle(false)

  return (
    <AuthModalContext.Provider value={{ authModalIsOpen: on, onAuthModalChange: toggle }}>
      {children}

      <Suspense>
        <AuthModal isOpen={on} onChange={toggle} />
      </Suspense>
    </AuthModalContext.Provider>
  )
}

export const useAuthModalContext = () => useContext(AuthModalContext)
