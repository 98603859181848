import { mapAuthUser } from '../../../auth-user/api/mapper'
import { ILogin, ILogout } from '../../model'
import { ILoginDto, ILogoutDto } from '../dto'

export const mapLogin = (dto: ILoginDto): ILogin => {
  return {
    message: dto.message ?? null,
    errors: dto.errors ?? null,
    isSuccess: !dto.errors,
    isError: !!dto.errors,

    data: {
      token: dto.data?.token ?? null,
      customer: mapAuthUser(dto),
    },
  }
}

export const mapLogout = (dto: ILogoutDto): ILogout => {
  return {
    message: dto.message ?? null,
    errors: dto.errors ?? null,
    isSuccess: !dto.errors,
    isError: !!dto.errors,

    data: {
      token: dto.data?.token ?? null,
      customer: mapAuthUser(dto),
    },
  }
}
