'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import { AUTH_QUERY_NAME } from '@/src/features/auth/config'

import { useAuthModalContext } from '../context'

export const useAuthLogin = () => {
  const { onAuthModalChange } = useAuthModalContext()
  const searchParams = useSearchParams()

  const hasAuthQuery = searchParams.has(AUTH_QUERY_NAME)

  useEffect(() => {
    onAuthModalChange(hasAuthQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAuthQuery])

  return { onAuthModalChange }
}
