import toast from 'react-hot-toast'
import { mutate } from 'swr'

import { SESSION_TOKEN_PLAYTEST_KEY_NAME } from '@/src/app/config/constants'
import { authApi } from '@/src/entities/auth'
import { ILoginWithTelegramBody } from '@/src/entities/auth/api/dto'
import { AUTH_USER_REVALIDATE_KEY } from '@/src/entities/auth-user/config'
import { GAMES_FRAMES_PLAYTEST_REVALIDATE_KEY } from '@/src/entities/game/config'
import { SESSION_REVALIDATE_PLAYTEST_KEY } from '@/src/entities/session/config'
import { setSessionToken } from '@/src/shared/api/storage'
import { handleError } from '@/src/shared/lib/handleError'
import { logger } from '@/src/shared/lib/logger'
import { IForm } from '@/src/shared/model'

const handleLogin = (response: IForm) => {
  const token = response?.data?.token
  const customer = response?.data?.customer

  setSessionToken({ token, sessionTokenKeyName: SESSION_TOKEN_PLAYTEST_KEY_NAME })

  mutate([SESSION_REVALIDATE_PLAYTEST_KEY], { token }, { revalidate: false })
  mutate([AUTH_USER_REVALIDATE_KEY], customer, { revalidate: false })
  mutate([GAMES_FRAMES_PLAYTEST_REVALIDATE_KEY])
}

export const restore = async <TFormBody>(_: unknown, formBody: TFormBody) => {
  return await authApi.restore(formBody)
}

export const register = async <TFormBody>(prevState: IForm, formBody: TFormBody) => {
  try {
    const response = await authApi.registration(formBody)

    if (response.isSuccess) {
      handleLogin(response)
    } else {
      const { message, errors } = response

      if (errors?.code || errors?.recaptcha_token) {
        toast.error(message)
      }

      logger.error(handleError(response))
    }

    return response
  } catch (e) {
    logger.error(handleError(e))

    return prevState
  }
}

export const login = async <TFormBody>(prevState: IForm, formBody: TFormBody) => {
  try {
    const response = await authApi.login(formBody)

    if (response.isSuccess) {
      handleLogin(response)
    } else {
      const { message, errors } = response

      if (errors?.recaptcha_token) {
        toast.error(message)
      }

      logger.error(handleError(response))
    }

    return response
  } catch (e) {
    logger.error(handleError(e))

    return prevState
  }
}

export const loginWithTelegram = async (prevState: IForm, formBody: ILoginWithTelegramBody) => {
  try {
    const response = await authApi.loginWithTelegram(formBody)

    if (response.isSuccess) {
      handleLogin(response)
    } else {
      const { message, errors } = response

      if (errors?.code) {
        toast.error(message)
      }

      logger.error(handleError(response))
    }

    return response
  } catch (e) {
    logger.error(handleError(e))

    return prevState
  }
}

export const logout = async (prevState: IForm) => {
  try {
    const response = await authApi.logout()

    if (response.isSuccess) {
      const { token, customer } = response.data

      setSessionToken({ token, sessionTokenKeyName: SESSION_TOKEN_PLAYTEST_KEY_NAME })

      mutate([SESSION_REVALIDATE_PLAYTEST_KEY], { token }, { revalidate: false })
      mutate([AUTH_USER_REVALIDATE_KEY], customer, { revalidate: false })
      mutate([GAMES_FRAMES_PLAYTEST_REVALIDATE_KEY])
    } else {
      logger.error(handleError(response))
    }

    return response
  } catch (e) {
    logger.error(handleError(e))

    return prevState
  }
}
