/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { useLocale } from 'next-intl'
import useSWR from 'swr'

import { ILang } from '@/src/app/model/lang'
import { apiClientPlaytest } from '@/src/shared/api'

import { getAuthUser } from '../api'
import { AUTH_USER_REVALIDATE_KEY } from '../config'

export const useAuthUser = () => {
  const locale = useLocale()
  apiClientPlaytest.setLocale(locale as ILang)

  const { data, mutate, isLoading, isValidating } = useSWR([AUTH_USER_REVALIDATE_KEY], () =>
    getAuthUser(),
  )

  return {
    data,
    mutate,
    isAuth: !!data,
    isLoading,
    isValidating,
    isLoadingOrValidating: isLoading || isValidating,
  }
}
