import { apiClientPlaytest } from '@/src/shared/api'

import { IAuthUser } from '../model'
import { IAuthUserDto } from './dto'
import { mapAuthUser } from './mapper'

export const getAuthUser = async (): Promise<IAuthUser> => {
  const res = await apiClientPlaytest.get<IAuthUserDto>('session')

  return mapAuthUser(res)
}
