/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { Viewer } from '@/src/entities/auth-user/ui/Viewer'
import { LogOut } from '@/src/features/auth/ui/LogOut'
import { Button } from '@/src/shared/ui/Button'

import { useAuthLogin } from '../../../Auth/hooks/useAuthLogin'

const Auth = () => {
  const { onAuthModalChange } = useAuthLogin()

  return (
    <Viewer
      slotToLogin={
        <Button
          onClick={onAuthModalChange}
          variant="fourth"
          squared
          size="s"
          iconName="user-icon"
        />
      }
      slotLogoutButton={<LogOut />}
    />
  )
}

export default Auth
