import { IForm, IMeta } from '../../model'
import { IFormDto, IMetaDto } from '../dto'

export const mapMeta = (dto: IMetaDto): IMeta => ({
  perPage: dto.per_page,
  lastPage: dto.last_page,
  currentPage: dto.current_page,
  total: dto.total,
  hasPagination: dto?.per_page < dto?.total,
})

export const mapForm = (dto: IFormDto): IForm => ({
  data: dto?.data ?? null,
  message: dto.message ?? null,
  errors: dto.errors ?? null,
  isSuccess: !dto.errors,
  isError: !!dto.errors,
  recaptcha_token: dto.recaptcha_token || null,
})
