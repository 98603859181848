import './Avatar.scss'

import cn from 'classnames'

import NoAvatar from '@/src/shared/images/no-avatar.png'
import { Image } from '@/src/shared/ui/Image'

export type IAvatar = {
  className?: string
  size?: 'xs' | 's' | 'm'
  title: string | null | undefined
  src: string | null | undefined
  withoutBorder?: boolean
}

const sizes = {
  xs: 33,
  s: 44,
  m: 71,
}

const Avatar = ({ className, size = 'm', title, src, withoutBorder }: IAvatar) => {
  const _src = src || NoAvatar

  return (
    <div
      className={cn('avatar', `avatar--size-${size}`, {
        [`${className}`]: !!className,
      })}
    >
      <div
        className={cn('avatar__inner', {
          ['avatar__inner--without-border']: withoutBorder,
        })}
      >
        <figure className="avatar__image">
          <Image
            fillParent
            src={_src}
            aspectRatio={{
              width: sizes[size],
              height: sizes[size],
            }}
            className="avatar__image"
            alt={title ?? ''}
          />
        </figure>
      </div>
    </div>
  )
}

export default Avatar
